<template>
    <section>
        <modal ref="editarProd" :titulo="producto.nombre" :img="producto.imagen" :regresar="tipo!=''?true:false" @regresar="tipo=''" @guardar="accion_producto">
            <div class="row mx-0 my-2 f-600 f-14 justify-center">
                {{ convertMoneyTendero(producto.precio,pedido.idm_moneda) }}
            </div>
            <div class="row mx-0 my-2 mt-3 f-15 justify-center">
                <div class="col-10 text-center">
                    {{ producto.descripcion }}
                </div>
            </div>
            <div class="row mx-0 my-2 f-15 justify-center">
                <b class="mr-2">
                    Presentación:
                </b>
                <p>{{producto.presentacion}}</p>
            </div>
            <div class="row mx-0 my-2 f-15 justify-center">
                <b class="mr-2">
                    Unidad de Medida:
                </b>
                <p>{{producto.unidad}}</p>
            </div>
            <div class="row mx-0 my-2 f-15 justify-center">
                <b class="mr-2">
                    Venta Mínima:
                </b>
                <p>{{ producto.cantidad_minima }} {{ producto.unidad }}</p>
            </div>
            <div class="row mx-0 my-2 f-15 justify-center">
                <b class="mr-2 text-general">
                    Cantidad Pedida:
                </b>
                <p>{{ producto.cantidad_pedida }}</p>
            </div>
            <hr />
            <div v-show="tipo=='' || tipo == 'cantidad'" class="">
                <div class="row justify-center">
                    <div class="col-4 px-0 text-center br-10  mx-2 position-relative">
                        <label class="position-absolute f-12" style="top:-17px; left:41px">Editar Cantidad</label>
                        <el-input-number
                        v-model="model.cantidad"
                        :min="minimo" :step="salto"
                        :step-strictly="entero"
                        :precision="presicion"
                        style="width:160px;"
                        @input="tipo = 'cantidad'"
                        />
                    </div>
                    <div class="col-3 text-center br-10 bg-secondary text-white cr-pointer p-2" @click="modalEliminarProd">
                        Eliminar
                    </div>
                </div>
            </div>
            <div v-show="tipo == 'cantidad' || tipo == ''" class="row mx-auto px-3 mt-4">
                <div class="w-100">
                    <label class="f-13">Comentario</label>
                    <el-input v-model="comentario" type="textarea" :rows="2" show-word-limit maxlength="200" />
                </div>
            </div>
        </modal>
        <modalConfirmar
        ref="modalEliminarProd"
        titulo="Eliminar producto"
        mensaje="¿Estás seguro de eliminar el producto del pedido?" icon="shopping-cart"
        @guardar="eliminar_producto"
        />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-tienda'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {

            tipo: '',
            comentario:'',
            loading:false,
            minimo:0,
            salto:0,
            entero:false,
            presicion:0,
            opciones:[],
            datos:{},
            producto:{nombre:''},
            model:{
                id_producto_nuevo:null,
                cantidad:'',
                cantidad_nueva:0
            }

        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
            pedido: 'pedidos/pedidos/pedido',
        })
    },
    methods: {
        toggle(){
            this.$refs.editarProd.toggle()
        },
        limpiar(){
            this.tipo = ''
            this.model = {
                cantidad:0,
            }
            this.minimo = 0
            this.salto = 0
            this.entero= false
            this.presicion =0
        },
        async datos_basicos(prod){
            this.limpiar()
            this.datos = prod

            this.minimo = parseFloat(prod.producto.cantidad_minima)
            this.salto = parseFloat(prod.producto.cantidad_minima)
            this.entero = prod.producto.cantidad_tipo === 1
            this.presicion = prod.producto.cantidad_tipo === 1 ? 0 : 2
            this.model.cantidad = parseFloat(prod.cantidad)
            this.comentario = prod.estado_comentario
            this.producto = {
                id:prod.producto.id,
                nombre:prod.producto.nombre,
                descripcion:prod.producto.descripcion,
                imagen:prod.producto.imagen,
                precio: prod.unidad_final,
                presentacion:prod.producto.presentacion,
                unidad:prod.producto.unidad,
                cantidad_minima:this.agregarSeparadoresNumero(prod.producto.cantidad_minima,2),
                cantidad_pedida: this.agregarSeparadoresNumero(prod.cantidad,2) + prod.producto.unidad + ': ' + this.convertMoneyTendero(prod.total_final,this.pedido.idm_moneda)

            }
            this.$refs.editarProd.toggle()
        },
        async accion_producto(){
            try {

                if(this.comentario === '' && this.tipo === '') return
                let model = {
                    cantidad:this.model.cantidad,
                    comentario:this.comentario
                }
                const {data} = await Pedidos.change_producto(this.datos.id, model)
                this.notificacion('Registro Actualizado','','success')
                //recalculamos todos los datos
                this.$store.commit('pedidos/pedidos/alter_producto',data.registro)
                this.$store.dispatch('pedidos/pedidos/pedidos_resumenes', this.id_pedido)

                this.$refs.editarProd.toggle()

            } catch (e){
                this.error_catch(e)
            } finally {

            }
        },
        modalEliminarProd(){

            this.$refs.editarProd.toggle()
            this.$refs.modalEliminarProd.toggle()
            // this.$emit('eliminar',this.datos.id)
        },
        async eliminar_producto(){
            try {
                const {data} = await Pedidos.eliminar_producto_manual(this.datos.id)

                this.notificacion('Mensaje','Producto Eliminado del pedido','success')
                this.$refs.modalEliminarProd.toggle()
                //recalculamos todos los datos
                this.$store.commit('pedidos/pedidos/delete_producto',this.datos.id)
                this.$store.dispatch('pedidos/pedidos/pedidos_resumenes', this.id_pedido)

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
